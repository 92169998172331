* {
  font-family: 'Satoshi', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden auto;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  text-decoration: none;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi/Satoshi-Light.woff2') format('woff2'),
    url('./assets/fonts/Satoshi/Satoshi-Light.woff') format('woff'),
    url('./assets/fonts/Satoshi/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/fonts/Satoshi/Satoshi-Regular.woff') format('woff'),
    url('./assets/fonts/Satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('./assets/fonts/Satoshi/Satoshi-Medium.woff') format('woff'),
    url('./assets/fonts/Satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('./assets/fonts/Satoshi/Satoshi-Bold.woff') format('woff'),
    url('./assets/fonts/Satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Yeager';
  src: url('./assets/fonts/Yeager-Light.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yeager';
  src: url('./assets/fonts/Yeager-Regular.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yeager';
  src: url('./assets/fonts/Yeager-Bold.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yeager-Numbers';
  src: url('./assets/fonts/Yeager-Bold-Numbers.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Yeager-Numbers';
  src: url('./assets/fonts/Yeager-Regular-Numbers.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

p,
span {
  font-family: 'Satoshi', sans-serif;
}

.activity-modal {
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 20px;
  max-width: 1300px;
  background-color: white;
  z-index: 3;
}

.resolver-modal {
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 130px;
  left: 360px;
  right: 360px;
  bottom: 130px;
  background-color: white;
  z-index: 3;
}

.activity-overlay {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.setting-modal {
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  top: 30px;
  left: 20%;
  right: 20%;
  bottom: 30px;
  background-color: white;
  z-index: 3;
}

.profile-modal {
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

[data-scrollbar] {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  background: #000;
}

.scroll-content {
  overflow: hidden !important;
  will-change: transform;
}

.scrollbar-track {
  background: 0 0 !important;
  z-index: 200 !important;
}

.scrollbar-thumb-y {
  right: 0 !important;
  width: 5px !important;
  background: #000 !important;
  border-radius: 0 !important;
}

.scrollbar-thumb-y:hover {
  cursor: grab;
}

#modal-root {
  position: relative;
  z-index: 3;
}

#inner-modal-root {
  position: relative;
  z-index: 4;
}

#second-inner-modal-root {
  position: relative;
  z-index: 4;
}
