.kodex-table {
  overflow-x: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.kodex-table [data-scrollbar] {
  background: white;
  width: auto;
  height: auto;
}

.kodex-table .table-row {
  display: flex;
  padding: 5px 0;
  background: transparent;
  width: 100%;
}

.kodex-table .table-row.table-row-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.kodex-table .table-cell {
  display: flex;
  flex: 1 0 100px;
  align-items: center;
  overflow: hidden;
  background: white;
}

.kodex-table .table-cell .table-cell-content {
  width: 100%;
  font-family: 'Satoshi', sans-serif;
  padding: 5px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #212121;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.kodex-table .table-row-header .table-cell .table-cell-content {
  font-size: 13px;
  color: #bcbccc;
}

.kodex-table .table-cell .table-cell-content a {
  color: #212121;
}

.kodex-table .table-cell.active-on-hover {
  visibility: hidden;
}
.kodex-table .table-row:hover .table-cell.active-on-hover {
  visibility: visible;
}
