.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  font-size: 30px !important;
  line-height: 30px !important;
  color: #212121;
  font-family: 'Satoshi', sans-serif !important;
}

html {
  -webkit-tap-highlight-color: transparent;
}
